<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success" v-if="!isEdit">
      <el-step title="机构信息" />
      <el-step title="密钥信息" />
    </el-steps>
    <div>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-if="!isEdit"
        v-show="active === 0 && !isEdit">
        <ykc-form-item label="机构名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字（1~30字数），不可重复"
            maxlength="50"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="公司名称" prop="companyName">
          <ykc-input placeholder="可重复" maxlength="50" v-model="ruleForm.companyName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="统一社会信用代码"
          prop="socialCreditCode"
          bottomTip="温馨提示：请认真核对营业执照上的18位编码">
          <ykc-input
            placeholder="请输入统一社会信用代码"
            maxlength="18"
            v-model="ruleForm.socialCreditCode"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="机构管理员" prop="manageName">
          <ykc-input
            placeholder="请输入机构管理员姓名"
            maxlength="50"
            v-model="ruleForm.manageName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系方式" prop="contactInfo">
          <ykc-input
            placeholder="请输入机构管理员联系方式"
            maxlength="11"
            v-model="ruleForm.contactInfo"></ykc-input>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-if="active === 1 || isEdit">
        <ykc-form-item label="机构密钥" prop="secretKey">
          <ykc-input
            placeholder="请输入机构密钥"
            maxlength="30"
            v-model="ruleForm.secretKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="消息密钥" prop="dataEncryptionKey">
          <ykc-input
            placeholder="请输入消息密钥"
            maxlength="30"
            v-model="ruleForm.dataEncryptionKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="初始化向量" prop="initializationVector">
          <ykc-input
            placeholder="请输入初始化向量（16位数字、字母）"
            maxlength="16"
            v-model="ruleForm.initializationVector"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="签名秘钥" prop="signKey">
          <ykc-input
            placeholder="请输入签名密钥"
            maxlength="30"
            v-model="ruleForm.signKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="接口Url" prop="interconnectionUrl">
          <ykc-input
            placeholder="请输入接口Url"
            maxlength="60"
            v-model="ruleForm.interconnectionUrl"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { operatorData } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {}, // 详情数据
        dictionary: {},
        organizations: [],
        pictureArr: [
          {
            name: '上传图片',
            url: '',
          },
        ],
        ruleForm: {
          operatorType: '2', // 互联商户
          operatorId: '',
          name: '',
          companyName: '',
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
          secretKey: '',
          dataEncryptionKey: '',
          initializationVector: '',
          interconnectionUrl: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入机构名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('机构名称'),
            },
          ],
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('公司名称'),
            },
          ],
          socialCreditCode: [
            { required: true, message: '请输入18位社会统一信用代码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.creditCode.regexp,
              message: regexpObj.regexp.input.creditCode.errorTips.error('社会统一信用代码'),
            },
          ],
          manageName: [
            { required: true, message: '请输入机构管理员姓名', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('机构管理员姓名'),
            },
          ],
          contactInfo: [
            { required: true, message: '请输入机构管理员联系方式', trigger: 'change' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('机构管理员联系方式'),
            },
          ],
          secretKey: [{ required: true, message: '请输入机构秘钥', trigger: 'change' }],
          dataEncryptionKey: [{ required: true, message: '请输入消息秘钥', trigger: 'change' }],
          initializationVector: [
            { required: true, message: '请输入初始化向量', trigger: 'change' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType_16.regexp,
              message: '请输入正确的初始化向量（16位数字、字母）',
            },
          ],
          signKey: [{ required: true, message: '请输入签名密钥', trigger: 'change' }],
          interconnectionUrl: [{ required: true, message: '请输入接口Url', trigger: 'change' }],
        },
      };
    },
    created() {
      // 新增调用电站树
      this.$store
        .dispatch('getDictionaryByKeys', [
          'ENABLE_STATE',
          'APP_VISIBILITY',
          'OPERATOR_ATTRIBUTES',
          'OPERATOR_INVOICE_ATTRIBUTES',
          'SELECT_METHOD',
          'CONFIG_TYPE_NEW',
          'UPLOAD_METHOD',
        ])
        .then(res => {
          // console.log(res, 'res');
          this.dictionary = res;
          const all = { id: null, name: '全部' };
          this.dictionary.ENABLE_STATE = [all, ...(this.dictionary.ENABLE_STATE ?? [])];
          this.dictionary.APP_VISIBILITY = [all, ...(this.dictionary.APP_VISIBILITY ?? [])];
          this.dictionary.OPERATOR_ATTRIBUTES = [
            all,
            ...(this.dictionary.OPERATOR_ATTRIBUTES ?? []),
          ];
          this.dictionary.OPERATOR_INVOICE_ATTRIBUTES = [
            all,
            ...(this.dictionary.OPERATOR_INVOICE_ATTRIBUTES ?? []),
          ];
        });

      // 编辑回显
      if (this.id) {
        operatorData
          .operatorDetail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
          operatorId: this.id, // 编辑需要传
        };
        return formData;
      },
    },
    methods: {
      /** 导入电站 */
      stationUpload(file, fileList, isCheck) {
        if (isCheck) {
          operatorData
            .station_upload({ file: file.raw })
            .then(res => {
              // console.log('res---站点导入', res);
              this.formData.configIds = res.stationIds;
            })
            .catch(() => {
              this.$refs.stationUpload.fileList = [];
            });
          this.$refs.ruleForm1.validateField('stationUpload');
        }
      },
      /**
       * 清除文件
       */
      handleRemove(propName) {
        this.ruleForm[propName] = [];
      },
      /**
       * 方案名称唯一性校验
       */
      onlyCheckRuleName(rule, value, callback) {
        if (value) {
          operatorData
            .checkName({ ruleName: value })
            .then(res => {
              if (res && res.data && res.data.resultCode !== '0') {
                this.nameValidError = res.data.resultDesc;
                callback(new Error(this.nameValidError));
              } else {
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /**
       * @desc 获取机构下拉列表
       * */
      doFetchOrgInfos() {
        operatorData
          .getFleets({})
          .then(response => {
            this.organizations = response.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              operatorData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              operatorData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /** 校验所有表格是否通过 */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
